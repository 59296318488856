import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import styled, { css } from 'styled-components'
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer'
import { Carousel } from '@/components/Carousel'
import { CaseCard } from '@/components/CaseCard'
import Layout from '@/components/Layout'
import { LayoutContainer } from '@/components/LayoutContainer'
import { Seo } from '@/components/Seo'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig, mixins, zIndex } from '@/utils/styleConfig'
import { HeadingLevel1 } from '@/components/HeadingLevel1'
import { HeadingLevel2 } from '@/components/HeadingLevel2'
import { Case } from '@/components/types'
import { metaData } from '@/feature/staticData'

export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      body
      slug
      frontmatter {
        title
        carouselImageCount
        caseTags
        caseType
      }
      relatedCase {
        id
        slug
        frontmatter {
          caseTags
          caseType
          date
          title
        }
      }
    }
  }
`

export const casePageTemplate = props => {
  const casePage = props.data.mdx
  const { frontmatter: currentPage } = casePage
  const { prev, next } = props.pageContext
  const relatedCasePages = casePage.relatedCase

  const filteredRelatedCasePages = relatedCasePages.filter(relatedPage =>
    relatedPage.frontmatter.caseTags.includes(currentPage.caseTags[0]),
  )

  const sortedRelatedCasePages = filteredRelatedCasePages
    .slice()
    .sort((prevCasePage, nextCasePage) => {
      if (Number(prevCasePage.slug) > Number(nextCasePage.slug)) {
        return -1
      }
      if (Number(prevCasePage.slug) < Number(nextCasePage.slug)) {
        return 1
      }
    })
    .slice(0, 4)

  return (
    <Layout>
      <Seo page="template" title={currentPage.title} url={`/case/${casePage.slug}/`} />
      <Article type={currentPage.caseType}>
        <LayoutContainer>
          <CaseHeading>
            <HeadingLevel1 subHeading="事例紹介">{currentPage.title}</HeadingLevel1>
            <TagList>
              {currentPage.caseTags.map(tag => (
                <li key={tag}>#{tag}</li>
              ))}
            </TagList>
          </CaseHeading>
        </LayoutContainer>

        {currentPage.carouselImageCount && (
          <CarouselContainer>
            <Carousel pageId={casePage.slug} imgCount={currentPage.carouselImageCount} />
          </CarouselContainer>
        )}

        <LayoutContainer>
          <MdxContainer>
            <MDXRenderer
              frontmatter={casePage.frontmatter}
              scope={undefined}
              children={casePage.body}
            />
          </MdxContainer>

          <CaseLinkContainer>
            {next && (
              <Link to={`${metaData.case.url}${next.slug}/`}>
                <NextCaseLink>
                  <img src="/images/case/icon_chevron-l.svg" alt="" width="18" height="28" />
                  前の事例
                </NextCaseLink>
              </Link>
            )}

            <Link to={`${metaData.case.url}`}>
              <CaseLink>
                <img src="/images/case/icon_dots.svg" alt="" width="24" height="24" />
                一覧へ戻る
              </CaseLink>
            </Link>

            {prev && (
              <Link to={`${metaData.case.url}${prev.slug}/`}>
                <PrevCaseLink>
                  <img src="/images/case/icon_chevron-r.svg" alt="" width="18" height="28" />
                  次の事例
                </PrevCaseLink>
              </Link>
            )}
          </CaseLinkContainer>

          <RelativeCase>
            {sortedRelatedCasePages.length !== 0 && (
              <>
                <RelativeCaseHeading>
                  <HeadingLevel2 note="related">関連記事</HeadingLevel2>
                </RelativeCaseHeading>
                <CaseCardList>
                  {sortedRelatedCasePages.map(relatedCasePage => (
                    <CaseCardListItem cardSize="small" key={relatedCasePage.id}>
                      <CaseCard
                        link={`/case/${relatedCasePage.slug}/`}
                        heading={relatedCasePage.frontmatter.title}
                        imgSrc={`/images/case/${relatedCasePage.slug}/1.jpg`}
                        caseType={relatedCasePage.frontmatter.caseType}
                        cardSize="small"
                      />
                    </CaseCardListItem>
                  ))}
                </CaseCardList>
              </>
            )}
          </RelativeCase>
        </LayoutContainer>
      </Article>
    </Layout>
  )
}

type ArticleProps = {
  type: Case
}

const Article = styled.article<ArticleProps>`
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: ${zIndex.background};
    top: -10rem;
    right: -2.8rem;
    margin: auto;
    width: 16rem;
    height: 16rem;
    background: ${({ type }) => {
      return `top / 100% no-repeat url(/images/top/img-${type.toLowerCase()}.png)`
    }};
  }

  ${breakpointUp('md')} {
    &::before {
      top: -12rem;
      left: 0;
      right: 0;
      transform: translateX(38rem);
      margin: auto;
      width: 34.8rem;
      height: 34.8rem;
    }
  }
`

const CaseHeading = styled.div`
  ${breakpointUp('md')} {
    width: 75rem;
  }
`

const TagList = styled.ul`
  display: flex;
  gap: 0 2rem;
  flex-wrap: wrap;
  color: ${colorConfig.themeColor};
  font-size: 1.4rem;
  margin-top: 1.2rem;

  ${breakpointUp('md')} {
    margin-top: 0.4rem;
  }
`

const MdxContainer = styled.div`
  h2 {
    margin-bottom: 2.4rem;
    font-size: 3.2rem;
    color: ${colorConfig.themeColor};
    line-height: 1.3;
    letter-spacing: 0.04em;

    ${breakpointUp('md')} {
      line-height: 1.4;
      margin-bottom: 3rem;
    }
  }

  > p + p {
    margin-top: 2.4rem;

    ${breakpointUp('md')} {
      margin-top: 2.8rem;
    }
  }
`

const CarouselContainer = styled.div`
  ${breakpointDown('sm')} {
    margin-top: 5.6rem;
    margin-bottom: 8.8rem;
  }

  ${breakpointUp('md')} {
    margin: 5.6rem auto 6.4rem;
    max-width: 1024px;
  }

  ${breakpointUp('lg')} {
    max-width: none;
  }
`

const CaseCardList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;

  ${breakpointUp('md')} {
    grid-auto-flow: dense;
    grid-template-columns: repeat(4, 1fr);
    gap: ${mixins.vw({ size: 24 })};
  }

  ${breakpointUp('lg')} {
    gap: 2.4rem;
  }
`

const CaseCardListItem = styled.li<{ cardSize: string }>`
  ${({ cardSize }) =>
    cardSize === 'large'
      ? css`
          grid-row: span 2;
          grid-column: span 2;
        `
      : css`
          width: 100%;
        `}
`

const CaseLinkContainer = styled.div`
  position: relative;
  background-color: ${colorConfig.bgAttentionColor};

  ${breakpointDown('sm')} {
    margin: 8rem -3rem 0;
    padding: 2.4rem 3rem 1.6rem;
  }

  ${breakpointUp('md')} {
    margin-top: 8rem;
    padding: 2.8rem 3rem 2.8rem;
  }

  > a {
    display: block;
    width: fit-content;
    margin: 0 auto;
  }
`

const CaseLink = styled.span`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: fit-content;
  text-align: center;

  ${breakpointDown('sm')} {
    flex-direction: column;
    justify-content: flex-end;
  }

  img {
    width: 2.4rem;

    ${breakpointDown('sm')} {
      margin: 0 auto 0.6rem;
    }

    ${breakpointUp('md')} {
      margin-right: 1.6rem;
    }
  }
`

const NextCaseLink = styled.span`
  display: flex;
  align-items: center;
  position: absolute;

  ${breakpointDown('sm')} {
    bottom: 1.6rem;
    left: 2.4rem;
    flex-direction: column;
  }

  ${breakpointUp('md')} {
    bottom: 2.8rem;
    left: 2.4rem;
    flex-direction: row;
  }

  ${breakpointUp('lg')} {
    left: 30rem;
  }

  img {
    width: 1.8rem;

    ${breakpointDown('sm')} {
      margin: 0 auto 0.4rem;
    }

    ${breakpointUp('md')} {
      margin-right: 1.6rem;
    }
  }
`

const PrevCaseLink = styled.span`
  display: flex;
  align-items: center;
  position: absolute;

  ${breakpointDown('sm')} {
    right: 2.4rem;
    bottom: 1.6rem;
    flex-direction: column;
  }

  ${breakpointUp('md')} {
    right: 2.4rem;
    bottom: 2.8rem;
    flex-direction: row-reverse;
  }

  ${breakpointUp('lg')} {
    right: 30rem;
  }

  img {
    width: 1.8rem;

    ${breakpointDown('sm')} {
      margin: 0 auto 0.4rem;
    }

    ${breakpointUp('md')} {
      margin-left: 1.6rem;
    }
  }
`

const RelativeCase = styled.div`
  margin-top: 8rem;

  ${breakpointUp('md')} {
    margin-top: 12rem;
  }
`

const RelativeCaseHeading = styled.div`
  margin-bottom: 4rem;
`

export default casePageTemplate
