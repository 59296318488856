import { useEffect, VFC } from 'react'
import styled from 'styled-components'
import { Navigation, Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { CarouselProps } from '@/components/types'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'

export const Carousel: VFC<CarouselProps> = ({ pageId, imgCount }) => {
  const imgSrcArray = []

  for (let index = 0; index < imgCount; index++) {
    imgSrcArray.push(`/images/case/${pageId}/${index + 1}.jpg`)
  }

  return (
    <OverRide>
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        slidesPerView="auto"
        centeredSlides={true}
        autoHeight={true}
        navigation
        pagination={{ type: 'fraction' }}
      >
        {imgSrcArray.map(imgSrc => (
          <SwiperSlide key={imgSrc}>
            <img src={imgSrc} alt="" width="762" height="462" />
          </SwiperSlide>
        ))}
      </Swiper>
    </OverRide>
  )
}

const OverRide = styled.div`
  .swiper {
    padding-bottom: 6.6rem;

    ${breakpointUp('md')} {
      padding-bottom: 6.4rem;
    }
  }

  .swiper-wrapper {
    ${breakpointUp('md')} {
    }
  }

  .swiper-slide {
    max-width: 1024px;
    margin: 0 auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: auto;
    bottom: 0.2rem;

    ${breakpointUp('md')} {
      bottom: 0;
    }

    ${breakpointUp('lg')} {
      left: calc(50% - 51.2rem);
    }

    &::before {
      content: '';
      display: block;
      width: 1.8rem;
      height: 3rem;
      background: top / 100% no-repeat url(/images/top/img-arrow.png);
    }

    &::after {
      display: none;
    }
  }

  .swiper-button-prev {
    ${breakpointDown('sm')} {
      left: 3.6rem;
    }

    ${breakpointUp('lg')} {
      margin-left: 0.8rem;
    }
  }

  .swiper-button-next {
    ${breakpointDown('sm')} {
      right: 3.6rem;
    }

    ${breakpointUp('md')} {
      left: 14rem;
    }

    ${breakpointUp('lg')} {
      margin-left: calc(50% - 51.2rem);
    }

    &::before {
      transform: scale(-1, 1);
    }
  }

  .swiper-pagination {
    pointer-events: none;
    font-size: 1.4rem;

    ${breakpointUp('md')} {
      padding-left: calc(50% - 51.2rem);
      margin-left: 6.6rem;
      text-align: left;
    }
  }

  img {
    height: 100%;
  }
`
